











import { Component } from 'vue-property-decorator'

import DiscountForm from '@/components/forms/discounts/DiscountForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerDiscountModule from '@/store/modules/manager/discounts'
import { SaleStoreData } from '@/store/types'

@Component({
  components: {
    DiscountForm,
  },
})
export default class SubjectDiscountNew extends NotifyMixin {
  private get breadcrumbs () {
    return [{ name: 'Скидки', path: { name: 'manager.commerce.discount.list' } }]
  }

  private handleSubmit(payload: { form: SaleStoreData, publish: boolean }) {
    ManagerDiscountModule.saveDiscount({ data: payload.form })
      .then(() => {
        this.notifySuccess('Скидка создана')
        this.$router.push({
          name: 'manager.commerce.discount.list',
        })
      })
      .catch(this.notifyError)
  }
}
